@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

.project {
    &-list {
        align-items: center;
        border: $border-width solid red;
        display: flex;
        pointer-events: none;
        width: 50vw;
        
        &-item {
            align-items: center;
            display: flex;
            border: $border-width solid red;
            display: flex;
            overflow: hidden;
            flex-direction: column;

            &-content {
                position: relative;

                img {
                    align-self: center;
                    max-height: 75vh;
                    max-width: 100%;
                }
            }
        }
    }

    &-info {
        margin-bottom: 3.5vw;

        &-text {
            margin: 0;
        }
    }

    &-popup {
        background-color: #ededed;
        border: $border-width solid red;
        height: 100%;
        left: 0;
        overflow: hidden;
        pointer-events: none;
        position: fixed;
        top: 0;
        transform: translateX(-100%);
        transition: transform 500ms ease;
        width: 50%;
        z-index: 2;

        a {
            box-shadow: inset 0 -2px 0 #ededed, inset 0 -3px 0 black;
        }    

        &--visible{
            border: $border-width solid green;
            pointer-events: all;
            transform: translateX(0);
        }

        &-content {
            border: $border-width solid pink;
            height: 100%;
            overflow: scroll;
            padding: 5vw calc(5vw + 25px) 5vw 5vw;
            margin-right: -25px;

            h1 {
                font-family: $heading-font-family;
                font-size: 2rem;
                margin: 0 0 0.95em;

                @media (min-width: 1700px) {
                    font-size: 2.5rem;
                }
            }

            img {
                display: block;
                margin-top: 2vw;
                width: 100%;

                &:first-of-type {
                    margin-top: 1.75vw;
                }
            }
        }

        &-close {
            border: $border-width solid red;
            height: $stroke-width-variable;
            height: 47px;
            width: 47px;
            padding: 0;
            position: fixed;
            right: 1.1em;
            top: 0.5em;
            width: $stroke-width-variable;
            z-index: 2;

            &::before,
            &::after {
                background: black;
                content: '';
                height: 100%;
                position: absolute;
                left: 50%;
                top: 0;
                transform-origin: center;
                width: $border-width-l;
            }

            &::before {
                transform: rotate(45deg) translate(0, 0);
            }
            &::after {
                transform: rotate(-45deg) translate(0, 0);
            }
        }
    }

    @media (max-width: 900px) {
        &-popup {
            width: 100%;
        }
    }
}
