.contact {
    font-family: $base-font-family;

    a {
        color: black;

        &:hover {
            color: rgba(black, 0.6);
        }
    }

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }
}
