html {
    color: $base-font-color;
    font-family: $base-font-family;
    font-size: 100%;
    line-height: $base-line-height;
}

body {
    font-size: $text-size;

    @media (min-width: 1700px) {
        font-size: $text-size-l;
    }
}

::selection {
    background: black;
    color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font-family;
    font-size: modular-scale(1);
    line-height: $heading-line-height;
    margin: 0 0 $small-spacing;
}

p {
    margin: 0 0 $small-spacing;
}

a {
    color: black;
    line-height: 0.5em;
    position: relative;
    text-decoration: none;
    transition: color $base-duration $base-timing;
    box-shadow: inset 0 -2px 0 white, inset 0 -3px 0 black;

    &:hover {
        color: rgba(black, 0.6);
    }

    &:focus, &:visited {
      color: #000;
      outline: none;
    }

    &[href^="mailto:"] {
        border: none;
    }
}

hr {
    border-bottom: $base-border;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin: $base-spacing 0;
}
