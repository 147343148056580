@font-face {
    font-family: 'BeatriceRegular';
    font-display: auto;
    src: url('../../source/fonts/Beatrice-Regular.eot');
    src: url('../../source/fonts/Beatrice-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../source/fonts/Beatrice-Regular.woff2') format('woff2'),
        url('../../source/fonts/Beatrice-Regular.woff') format('woff'),
        url('../../source/fonts/Beatrice-Regular.otf');
    font-style: normal;
    font-weight: normal;
}
@font-face {
    font-family: 'BeatriceBold';
    font-display: auto;
    src: url('../../source/fonts/Beatrice-Bold.eot');
    src: url('../../source/fonts/Beatrice-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../source/fonts/Beatrice-Bold.woff2') format('woff2'),
        url('../../source/fonts/Beatrice-Bold.woff') format('woff'),
        url('../../source/fonts/Beatrice-Bold.otf');
    font-style: normal;
    font-weight: bold;
}

// Typography
// $base-font-family: $font-stack-system;
// $heading-font-family: $base-font-family;
$base-font-family: 'BeatriceRegular', Helvetica, sans-serif;
$heading-font-family: 'BeatriceBold', Helvetica, sans-serif;

// Line height
$text-size: 20px;
$text-size-l: 22px;

$base-line-height: 1.3;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: 1.5em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #1565c0;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $blue;

// Border
$base-border-color: $light-gray;
$border-width: 0px;
$border-width-l: 8px;
$base-border: $border-width solid $base-border-color;
$stroke-width-variable: 3vw;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Focus
$focus-outline-color: transparentize($action-color, 0.4);
$focus-outline-width: 3px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: 2px;

// Animations
$base-duration: 150ms;
$base-timing: ease;
