.glide {
    border: $border-width solid red;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &__track {

    }

    &__button {
        position: absolute;
        height: 100%;
        left: 50%;
        top: 0;
        width: 35%;
        transform: translateX(-50%);
    }

    &__arrows {
        display: flex;
        height: 100%;
        justify-content: space-between;
        left: 50%;
        // pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }

    &__arrow {
        border: $border-width solid yellow;
        height: 100%;
        width: calc(50% + 5vw);
        padding: 0;
        position: absolute;

        &--left {
            left: 0;
            transform: translateX(-5vw);
            &:hover {
                cursor: url(../../source/images/arrow-left.svg) 25 25, auto;
            }
        }

        &--right {
            right: 0;
            transform: translateX(5vw);
            &:hover {
                cursor: url(../../source/images/arrow-right.svg) 25 25, auto;
            }
        }
    }

    &__bullet {
        &--active {
            background-color: green;
        }
    }

    @media (max-width: 900px) {
        top: 0;
        transform: translateY(0);
    }
}
