body {
    &.noscroll {
        overflow: hidden;
    }
}

.page {
    display: flex;

    img {
        display: block;
    }

    &-project-info {
        border: $border-width solid red;
        bottom: 5vw;
        left: 5vw;
        position: absolute;

        @media (max-width: 900px) {
            bottom: 0;
            left: 0;
            order: -1;
            position: relative;
            margin-bottom: 3em;
        }

        &-button {
            border-bottom: 1px solid black;
            font-size: $text-size;
            font-weight: 400;
            padding: 0.1em 0;
            pointer-events: all;
            right: 0;
            top: 0;

            @media (min-width: 1700px) {
                font-size: $text-size-l;
            }
        }
    }

    &-item {
        border: $border-width solid green;
        flex: 1;
        height: 100vh;
        max-width: 50vw;
        overflow: hidden;
        padding: 5vw;
        position: relative;
    }

    @media (max-width: 900px) {
        flex-direction: column-reverse;

        &-item {
            border: $border-width solid blue;
            height: auto;
            max-width: 100vw;
            padding: 10vw;
        }
    }
}

.blur-up {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms;
}

.blur-up.lazyloaded {
    -webkit-filter: blur(0);
    filter: blur(0);
}
